import axios from '@/lib/swag/api/axiosInstance';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';

export const LEADERBOARD_PERIOD = {
  daily: 'Daily',
  weekly: 'Weekly',
  alltime: 'All Time',
};

export type LeaderboardPeriod = keyof typeof LEADERBOARD_PERIOD;

interface SwagLeaderboardData {
  level_key: string
  value: string
  date_created: string
  screen_name: string
  avatarUrl: string
};

export interface LeaderboardData {
  displayName: string
  avatarUrl: string
  dateCreated: string
  value: string
};

interface SwagGameModeData {
  game: string
  name: string
  level_key: string
  value_type: 'time' | 'score'
  value_name: string
  reverse: boolean
  order: number
};

export interface GameModeData {
  key: string
  label: string
  reverse: boolean
  columnType: SwagGameModeData[ 'value_type' ]
  columnLabel: string
};

interface SwagUserBestData {
  dailyBest?: {
    value: string
  },
  scorePosition?: {
    value: number | '-'
  },
  totalScores?: {
    value: number | '-'
  }
};

export interface UserBestData {
  dailyBest: string | null
  scorePosition: number
  totalScores: number
};

export default class SwagModel {
  static transformLeaderboard (rawData: SwagLeaderboardData): LeaderboardData {
    return {
      displayName: rawData.screen_name,
      avatarUrl: rawData.avatarUrl,
      dateCreated:rawData.date_created,
      value: rawData.value,
    };
  }

  static transformGameMode (rawData: SwagGameModeData): GameModeData {
    return {
      key: rawData.level_key,
      label: rawData.name,
      reverse: rawData.reverse,
      columnType: rawData.value_type,
      columnLabel: rawData.value_name,
    };
  }

  static transformUserBest (rawData: SwagUserBestData): UserBestData {
    return {
      dailyBest: rawData.dailyBest?.value === '-' ? null : rawData.dailyBest?.value,
      scorePosition: rawData.scorePosition?.value === '-' ? 0 : rawData.scorePosition?.value,
      totalScores: rawData.totalScores?.value === '-' ? 0 : rawData.totalScores?.value,
    };
  }

  static async getGameId (keyword: string): Promise<string> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `game?keyword=${keyword}&keywordtype=shockwave`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Game ID', false, error);
      throw error;
    }

    return response.data.game;
  }

  static async getGameModes (gameId: string): Promise<GameModeData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/score/categories?game=${gameId}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Game Modes', false, error);
      throw error;
    }

    return response.data
      .sort((a: SwagGameModeData, b: SwagGameModeData) => a.order - b.order)
      .map((item: SwagGameModeData) => SwagModel.transformGameMode(item));
  }

  static async getScores (gameId: string, gameMode: string, period: LeaderboardPeriod): Promise<LeaderboardData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/scores?game=${gameId}&type=standard&level_key=${gameMode}&period=${period}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Scores', false, error);
      throw error;
    }

    return response.data
      .map((item: SwagLeaderboardData) => SwagModel.transformLeaderboard(item));
  }

  static async getUserScores (gameId: string, gameMode: string, period: LeaderboardPeriod): Promise<LeaderboardData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/userbest?game=${gameId}&type=standard&level_key=${gameMode}&period=${period}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG User Scores', false, error);
      throw error;
    }

    return response.data
      .map((item: SwagLeaderboardData) => SwagModel.transformLeaderboard(item));
  }

  static async getUserBest (gameId: string, gameMode: string, period: LeaderboardPeriod): Promise<UserBestData> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/scores/context?game=${gameId}&type=standard&level_key=${gameMode}&period=${period}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG User Best', false, error);
      throw error;
    }

    return SwagModel.transformUserBest(response.data);
  }

  static async getTokenBalance () {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: '/tokenbalance',
      });
    } catch (error) {
      logErrorEvent('Get SWAG Token Balance', false, error);
      throw error;
    }

    return response.data.total_tokens;
  }
};
