import { getIdentifier } from 'react-commons';
import { action, createStoreContext, CreateStoreOptions } from '@twocatmoon/react-actions';
import { captureEvent } from '@/lib/util/captureEvent';



// #region Schema & State

export interface ThemeState {
  theme: string
}

const initialState: ThemeState = {
  theme: 'default'
};

// #endregion



// #region Actions

export const themeActions = {
  setTheme: action<ThemeState, string>((prevState, theme) => {
    captureEvent('user_preferences', { theme: theme || 'default' });

    return {
      ...prevState,
      theme
    };
  }),
};

// #endregion



// #region Export

const options: CreateStoreOptions = {
  storageKey: getIdentifier('theme'),
  storageType: 'local',
  ssr: true,
};

export const { 
  Provider: ThemeStoreProvider, 
  useStore: useThemeStore, 
} = createStoreContext<ThemeState>(initialState, themeActions, options);

// #endregion
