import drupal from '@/lib/drupal/api/axiosInstance';
import { logErrorEvent } from '@/lib/util/logErrorEvent';
import axios from 'axios';
import { AxiosResponse } from 'axios';

export interface AuthData {
  userId: string
  roles: ('authenticated' | 'administrator')[]
  csrfToken: string
  logoutToken: string
}

export default class AuthModel {
  static async register (username: string, email: string, password: string, agreeToEmail: boolean): Promise<{ memberId: string }> {
    const payload = {
      name: [
        {
          value: username
        }
      ],
      mail: [
        {
          value: email
        }
      ],
      pass: [
        {
          value: password
        }
      ],
      field_opt_in: [
        {
          value: agreeToEmail ? '1' : '0'
        }
      ]
    };

    let response: AxiosResponse<{ field_member_id: [{ value: string }] }>;
    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/registerpass',
        params: { 
          _format: 'json'
        },
        data: payload
      });
    } catch (error) {
      logErrorEvent('Register', false, error);
      throw error;
    }
    
    // Return member ID
    return { memberId: response.data?.field_member_id[ 0 ]?.value || '' };
  }

  static async login (name: string, password: string): Promise<AuthData> {
    let response: AxiosResponse;

    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/login',
        params: { 
          _format: 'json'
        },
        data: { 
          name, 
          pass: password
        }
      });
    } catch (error) {
      logErrorEvent('Login - Drupal', false, error);
      throw error;
    }
    
    return {
      userId: response.data.current_user.uid,
      roles: response.data.roles,
      csrfToken: response.data.csrf_token,
      logoutToken: response.data.logout_token
    };
  }

  static async logout (logoutToken: string): Promise<void> {
    let response: AxiosResponse;

    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/logout',
        params: { 
          _format: 'json',
          token: logoutToken
        }
      });
    } catch (error) {
      logErrorEvent('Logout', true, error);
    }
  }

  static async token (): Promise<{ logoutToken: string, csrfToken: string }> {
    let response: AxiosResponse;
    try {
      response = await drupal.request({
        method: 'get',
        url: '/sw/api/get/user-account/tokens'
      });
    } catch (error) {
      logErrorEvent('Retrieve token', true, error);
      throw error;
    }

    return {
      logoutToken: response.data.logout_token,
      csrfToken: response.data.csrf_token,
    };
  }

  static async verify (): Promise<boolean> {
    let response: AxiosResponse;
    try {
      response = await drupal.request({
        method: 'get',
        url: '/user/login_status',
        params: {
          _format: 'json'
        },
      });
    } catch (error) {
      logErrorEvent('Verify authentication', true, error);
      throw error;
    }

    return (parseInt(response.data) as 0 | 1) === 1;
  }

  static async requestResetPassword (email: string): Promise<void> {
    let response: AxiosResponse;
    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/lost-password',
        params: {
          _format: 'json'
        },
        data: {
          mail: email,
          forgot_pass_link: `${process.env.NEXT_PUBLIC_BASE_URL}/account/reset`
        },
      });
    } catch (error) {
      logErrorEvent('Request password reset', false, error);
      throw error;
    }

    return response.data;
  }

  static async submitResetPassword (token: string, username: string, password: string): Promise<{ error: any, message: string }> {
    let response: AxiosResponse;
    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/lost-password-reset',
        params: {
          _format: 'json'
        },
        data: {
          name: username,
          token, 
          new_pass: password
        },
      });
    } catch (error) {
      logErrorEvent('Submit password reset', false, error);
      throw error;
    }

    return response.data;
  }
}
