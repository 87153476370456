import posthog from 'posthog-js';
import ReactGA from 'react-ga4';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export function captureEvent (event: string, data: any) {
  if (typeof data === 'object' && !data.tag_name) {
    data.tag_name = event;
  }

  // Google Analytics
  ReactGA.event(event, data);

  // PostHog
  posthog.capture(event, data);
}
