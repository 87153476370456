import '@/styles/app.scss';
import '@/styles/themes.scss';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/700.css';
// import '@/styles/dev.scss';

import pkg from '@/package.json';

import { useEffect, useRef } from 'react';
import { App, Modals, Notifications, setConfig, ComScore, storage, getIdentifier } from 'react-commons';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { AuthProvider, useAuthStore } from '@/lib/drupal/stores/auth';
import { AppStoreProvider } from '@/lib/stores/app';
import { useDrupalAuth } from '@/lib/drupal/hooks/useDrupalAuth';
import { ThemeStoreProvider, useThemeStore } from '@/lib/stores/theme';
import { RecentlyPlayedProvider } from '@/lib/stores/recentlyPlayed';

import BackgroundImage from '@/components/BackgroundImage';
import { captureEvent } from '@/lib/util/captureEvent';

setConfig({
  APP_DESCRIPTION: process.env.APP_CONFIG.DESCRIPTION,
  APP_KEYWORDS: process.env.APP_CONFIG.KEYWORDS,
  APP_SEARCH_URL: process.env.APP_CONFIG.SEARCH_URL,
  APP_THEME_COLOR: process.env.APP_CONFIG.THEME,
  APP_TITLE: process.env.APP_CONFIG.APP_TITLE,
  APP_TITLE_TEMPLATE: process.env.APP_CONFIG.TITLE_TEMPLATE,
  BASE_URL: process.env.APP_CONFIG.URL,
  COMSCORE_CLIENT_ID: process.env.APP_CONFIG.COMSCORE_CLIENT_ID,
  IDENTIFIER: '',
  NODE_ENV: process.env.NODE_ENV,
  PACKAGE_NAME: pkg.name,
  REPORT_LOG_ON_LEVELS: 'error,fatal'
});

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: '/ingest',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
    loaded: (posthog) => {
      // if (
      //   process.env.NODE_ENV === 'development' || 
      //   process.env.NEXT_HOST_ENV === 'staging'
      // ) {
      posthog.debug();
      // }
    },
  });
}

function AppInner ({ Component, pageProps }) {
  const [ themeState ] = useThemeStore();
  const [ authState ] = useAuthStore();
  const hasLoadedAdScriptRef = useRef(false);

  useEffect(
    () => {
      if (hasLoadedAdScriptRef.current) {
        return;
      }

      // If the auth store isn't ready, keep waiting until it is before making
      // any changes.
      if (!authState?.ready) {
        return;
      }

      // If the user doesn't have GamePass, load the ad scripts
      if (!authState?.user?.isPremiumUser) {
        const loadAdScript = () => {
          if (hasLoadedAdScriptRef.current) return;

          hasLoadedAdScriptRef.current = true;

          const scriptRef = document.getElementsByTagName('script')[ 0 ];
          const ympbScript = document.createElement('script');

          ympbScript.src = process.env.APP_CONFIG.YOLLA_SCRIPT_URL;
          scriptRef.parentNode.insertBefore(ympbScript, scriptRef);

          window.removeEventListener('scroll', loadAdScript);
          window.removeEventListener('mousemove', loadAdScript);
          window.removeEventListener('touchstart', loadAdScript);
        };

        window.addEventListener('scroll', loadAdScript);
        window.addEventListener('mousemove', loadAdScript);
        window.addEventListener('touchstart', loadAdScript);
      } else {
        hasLoadedAdScriptRef.current = true;
      }
    },
    [ authState?.ready, authState?.user?.isPremiumUser ]
  );

  return (
    <App
      theme={themeState.theme}
      authHook={useDrupalAuth}
      googleAnalyticsOptions={{
        uakey: process.env.APP_CONFIG.GOOGLE_UA_ID,
        ga4key: process.env.APP_CONFIG.GOOGLE_GA4_ID,
      }}
    >
      <ComScore clientId={process.env.APP_CONFIG.COMSCORE_CLIENT_ID} />
      <Modals />
      <Notifications />
      <Component {...pageProps} />
    </App>
  );
}

function _app ({ Component, pageProps }) {
  const router = useRouter();

  // // Track page views
  // useEffect(() => {
  //   // Track page views
  //   const handleRouteChange = () => posthog.capture('$pageview');
  //   router.events.on('routeChangeComplete', handleRouteChange);

  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Track initial user preferences
  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (!firstRenderRef.current) return;
    firstRenderRef.current = false;

    const themeData = storage.getLocal('theme');
    captureEvent('user_preferences', {
      theme: themeData?.theme || 'default',
      layout: 'classic',
    });
  }, []);

  return (
    <App.Provider
      authProvider={AuthProvider}
    >
      <PostHogProvider client={posthog}>
        <AppStoreProvider>
          <ThemeStoreProvider>
            <RecentlyPlayedProvider>
              <BackgroundImage />
              <AppInner
                Component={Component}
                pageProps={pageProps}
              />
            </RecentlyPlayedProvider>
          </ThemeStoreProvider>
        </AppStoreProvider>
      </PostHogProvider>
    </App.Provider>
  );
}

export default _app;
